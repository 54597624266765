<template>
  <v-dialog width="550" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn style="height: 30px; magin: 5px 0" elevation="0" block v-on="on">
        {{ $t("accounts.configuration") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $tc("marketplace", 2) }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col>
            Activando esta opción, puedes poner precio a tus servicios. Los
            precios que dejes vacíos, el cliente verá 'a determinar' en el
            proceso de pedir cita online. Será obligatorio que el cliente
            complete el proceso de pago antes de agendar la cita.
          </v-col>
        </v-row>
        <v-row align="center" v-if="hasService('tattooer')">
          <v-col class="py-1" cols="12" md="3">{{ $tc("tattooer") }}</v-col>
          <v-col class="py-1" cols="12" md="3">
            <v-select
              :disabled="$store.getters['auth/getSetting']('pm_stripe_id') == 0"
              outlined
              dense
              hide-details
              :items="items"
              v-model="tattooer_active"
          /></v-col>
          <v-col class="py-1" cols="12" md="6"><Tattooer /></v-col>
        </v-row>

        <v-row align="center" v-if="hasService('piercer')">
          <v-col class="py-1" cols="12" md="3">{{ $tc("piercer") }}</v-col>
          <v-col class="py-1" cols="12" md="3">
            <v-select
              :disabled="$store.getters['auth/getSetting']('pm_stripe_id') == 0"
              outlined
              dense
              hide-details
              :items="items"
              v-model="piercer_active"
          /></v-col>
          <v-col class="py-1" cols="12" md="6"><Piercer /></v-col>
        </v-row>

        <v-row align="center" v-if="hasService('microblading')">
          <v-col class="py-1" cols="12" md="3">{{ $tc("microblading") }}</v-col>
          <v-col class="py-1" cols="12" md="3">
            <v-select
              :disabled="$store.getters['auth/getSetting']('pm_stripe_id') == 0"
              outlined
              dense
              hide-details
              :items="items"
              v-model="microblading_active"
          /></v-col>
          <v-col class="py-1" cols="12" md="6"><Microblading /></v-col>
        </v-row>

        <v-row align="center" v-if="hasService('laser')">
          <v-col class="py-1" cols="12" md="3">{{ $tc("laser") }}</v-col>
          <v-col class="py-1" cols="12" md="3">
            <v-select
              :disabled="$store.getters['auth/getSetting']('pm_stripe_id') == 0"
              outlined
              dense
              hide-details
              :items="items"
              v-model="laser_active"
            />
          </v-col>
          <v-col class="py-1" cols="12" md="6"><Laser /></v-col>
        </v-row>
        <v-row align="center">
          <v-col class="py-1" cols="12" md="6">{{
            $tc("discounts.ask")
          }}</v-col>

          <v-col class="py-1" cols="12" md="6">
            <Discounts />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MarketplacesConfiguration",
  data() {
    return {
      dialog: false,
      items: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
    };
  },
  components: {
    Tattooer: () =>
      import(
        "@/components/user/settings/configuracion/marketplace/MarketplaceTattooer"
      ),
    Laser: () =>
      import(
        "@/components/user/settings/configuracion/marketplace/MarketplaceLaser"
      ),
    Microblading: () =>
      import(
        "@/components/user/settings/configuracion/marketplace/MarketplaceMicroblading"
      ),
    Piercer: () =>
      import(
        "@/components/user/settings/configuracion/marketplace/MarketplacePiercer"
      ),
    Discounts: () =>
      import("@/components/user/settings/configuracion/marketplace/Discounts"),
  },
  methods: {
    ...mapActions("marketplace", ["updateActiveMarketplace"]),
    hasService(service) {
      let services = JSON.parse(
        this.$store.getters["auth/getSetting"]("studio_services")
      );
      let s = services.find((x) => x.name === service);
      if (s) return true;
      return false;
    },
    setServiceMarketplace(type, value) {
      /*this.$confirm(
        this.$t(
          value ? "marketplace_ask_activate" : "marketplace_ask_deactivate",
          { type: this.$tc(type) }
        )
      ).then(() => {*/
      this.updateActiveMarketplace({
        marketplace: type,
        active: value,
      });
      //});
    },
  },
  computed: {
    tattooer_active: {
      get() {
        return this.$store.getters["auth/getSetting"](
          "marketplace_active_tattooer"
        );
      },
      set(value) {
        this.setServiceMarketplace("tattooer", value);
      },
    },
    piercer_active: {
      get() {
        return this.$store.getters["auth/getSetting"](
          "marketplace_active_piercer"
        );
      },
      set(value) {
        this.setServiceMarketplace("piercer", value);
      },
    },
    microblading_active: {
      get() {
        return this.$store.getters["auth/getSetting"](
          "marketplace_active_microblading"
        );
      },
      set(value) {
        this.setServiceMarketplace("microblading", value);
      },
    },
    laser_active: {
      get() {
        return this.$store.getters["auth/getSetting"](
          "marketplace_active_laser"
        );
      },
      set(value) {
        this.setServiceMarketplace("laser", value);
      },
    },
  },
};
</script>